/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useState } from 'react';
import { addData } from '../firebase';

const Footer = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [service, setService] = useState("");
    const [location, setLocation] = useState("");
    const [unit, setUnit] = useState("");
    const [note, setNote] = useState("");

    async function handleSubmit (event){
        event.preventDefault();
    await addData(name, email, mobile, service, location, unit, note);
      setName('');
      setEmail('');
      setMobile('');
      setService('');
      setLocation('');
      setUnit('');
      setNote('');
      alert(`submitted`);
    }

    return (
        <div className="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn" id="contact-section" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                    <h3 className="text-white mb-3">YoGreen Industries</h3>
                    <p>YoGreen Industries is committed to combining energy efficiency with solar power, caring for the planet and providing superior customer service. We have been names the #1 Fastest growing Business in Chhattisgarh, the #1 residential solar installer in bilaspur and the 356th fastest growing company in the nation on the inc. 500 list.</p>
                        <i className="fa fa-phone-alt" style={{float: 'left', lineHeight: 1.6}}></i><p className="ms-4">+91 9179955329, +91 6265538447 (Raipur)</p>
                        <i className="fa fa-phone-alt" style={{float: 'left', lineHeight: 1.6}}></i><p className="ms-4">+91 7089566534, +91 8269333336 (Bilaspur)</p>
                        <i className="fa fa-envelope" style={{float: 'left', lineHeight: 1.6}}></i><p className="ms-4">yogreen07@gmail.com</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-square btn-outline-light btn-social" href="https://www.instagram.com/yogreen_solar_industries/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square btn-outline-light btn-social" href="https://www.facebook.com/yogreenindustries/" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square btn-outline-light btn-social" href="https://www.youtube.com/@YogreenIndustry" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>
                            <a className="btn btn-square btn-outline-light btn-social" href="https://www.linkedin.com/in/yogreen-industries-073b3a197/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    <h5 className="text-white mb-4">Address</h5>
                        <i className="fa fa-map-marker-alt" style={{float: 'left', lineHeight: 1.6}}></i><p className="ms-4">Raipur Office :-  Yogreen Solar Industries, D-11 Sector-2, Near Ganesh Maidan, Avanti Vihar, Telibandha 492001</p>
                        <i className="fa fa-map-marker-alt" style={{float: 'left', lineHeight: 1.6}}></i><p className="ms-4">Bilaspur Office :- Shantiniketan Tower, Behind Gumber Petrol Pump, Vyapar Vihar (C.G.) 495001</p>
                        <i className="fa fa-map-marker-alt" style={{float: 'left', lineHeight: 1.6}}></i><p className="ms-4">Jagdalpur Office :- Shantiniketan Campus, Near Kosa Center Kumharpara Jagdalpur (C.G.) 494001</p>
                    </div>
                    <div className='col-lg-5 col-md-6'>
                    <h5 className="text-white mb-4">Get A Free Quote</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                            <div className="col-12 col-sm-6">
                                <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control border-0" placeholder="Your Name" style={{ height: '55px' }} required />
                            </div>
                            <div className="col-12 col-sm-6">
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control border-0" placeholder="Your Email" style={{ height: '55px' }} required />
                            </div>
                            <div className="col-12 col-sm-6">
                                <input type="tel" id="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control border-0" placeholder="Your Mobile" style={{ height: '55px' }} required />
                            </div>
                            <div className="col-12 col-sm-6">
                                <select id="service" value={service} onChange={(e) => setService(e.target.value)} className="form-select border-0" style={{ height: '55px' }}>
                                    <option value="" defaultValue>Select A Service</option>
                                    <option value="Domestic Rooftop">Domestic Rooftop</option>
                                    <option value="Commercial Rooftop">Commercial Rooftop</option>
                                    <option value="Industrial Power Plant">Industrial Power Plants</option>
                                    <option value="Government Schemes">Government Schemes (KUSUM/JJY/SSY)</option>
                                    <option value="Others(Solar Pumps/Heaters/Lights)">Others(Solar Pumps/Heaters/Lights)</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-6">
                                <textarea id="location" value={location} onChange={(e) => setLocation(e.target.value)} className="form-control border-0" placeholder="Your Address" style={{ height: '55px' }} required/>
                            </div>
                            <div className="col-12 col-sm-6">
                                <textarea id="unit" value={unit} onChange={(e) => setUnit(e.target.value)} className="form-control border-0" placeholder="Average Monthly Consumption (in KWH)" style={{ height: '55px' }} />
                            </div>
                            <div className="col-12">
                                <textarea id="note" value={note} onChange={(e) => setNote(e.target.value)} className="form-control border-0" placeholder="Special Note" style={{height:"75px"}}></textarea>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a href="#">YoGreen Industries</a>, All Right Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;